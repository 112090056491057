import React from 'react'
import ShowMoreText from "react-show-more-text";
import useViewport from '../../../hooks/useViewport';
import "./ItemSkill.css"

const ItemSkill = ({ item }) => {
    const { icon, title, description } = item;
    const { width } = useViewport();
    const breakpoint = 732;

    return (
        <>
            {width > breakpoint ?
                (
                    <div className="item-skill-container">
                        <div className="circle-icon">
                            <img src={icon} alt={`Ícono sobre: '${title}`} />
                        </div>
                        <div className="data-item-skill">
                            <p className="medium text-center">{title}</p>
                            <p className="small" style={{ color: "#3F3F3F" }}>{description}</p>
                        </div>
                    </div>
                )
                :
                (
                    <div className="item-skill-container">
                        <div className="circle-icon">
                            <img src={icon} alt={`Ícono sobre: '${title}`} />
                        </div>
                        <div className="data-item-skill">
                            <p className="medium text-center">{title}</p>
                            <ShowMoreText
                                /* Default options */
                                lines={2}
                                more="VER MAS"
                                less="VER MENOS"
                                className="description-feature"
                                anchorClass="description-feature-btn"
                                expanded={false}
                                width={220}
                                truncatedEndingComponent={"... "}
                            >
                                {description}
                            </ShowMoreText>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default ItemSkill