import React, { useEffect } from 'react'
import CarouselReviews from '../../common/CarouselReviews/CarouselReviews'
import Aos from 'aos'
import './Reviews.css'

const Reviews = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    return (
        <div className="reviews-container">
            <div className="reviews-contain">
                <h4 data-aos="fade" style={{ textAlign: "center", color: "white" }}>Testimonio de clientes</h4>
                <div data-aos="fade" className="carousel-contain">
                    <CarouselReviews />
                </div>
            </div>
        </div>
    )
}

export default Reviews
