import React, { useContext, useEffect } from 'react'
import mockupMovil from '../../../images/Mockup-Cel-1.png'
import mockupLaptop from '../../../images/Mockup-Laptop-1.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuContext } from '../../../contexts/MenuContext'
import Aos from 'aos'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import './Present.css'
import 'react-lazy-load-image-component/src/effects/opacity.css';

const Present = () => {
    const { goToPage } = useContext(MenuContext);
    const location = useLocation();
    const navigate = useNavigate();

    const openPopup = (popup) => {
        if (location.pathname.length > 1) {
            navigate(`${location.pathname}/${popup}`)
        } else {
            navigate(`/inicio/${popup}`)
        }
    }

    useEffect(() => {
        Aos.init({ duration: 3000 })
    }, [])

    return (    
        <div id="inicio" className="present-container">
            <div className="cuarto-circulo"></div>
            <div className="present-contain">
                <div className="text" data-aos="fade">
                    <div style={{ position: "relative" }} className="text-container">
                        <h2 style={{marginBottom: 0}}>
                            Si sos de las personas que les gusta tener todo bajo control, esto es para vos.
                        </h2>
                        <p className="medium">
                            Tener un negocio y una vida a la vez, es posible.
                        </p>
                        <br />
                        <br />
                        <button className="button primary mr-8" onClick={() => openPopup('solicitar')}>¡Quiero dar el salto!</button>
                        <button className="button secondary" onClick={() => goToPage('soluciones')}>Ver más</button>
                        <div className="div_mouse">
                            <div className="content_mouse">
                                <div className="mouse" />
                            </div>
                            <p>desplazate</p>
                        </div>
                    </div>
                </div>
                <div className="images">
                    <div className="celu">
                        <LazyLoadImage src={mockupMovil} height={243} alt="Mock-up LondonManager Móvil" effect="opacity" />
                        <div style={{ zIndex: -1 }} className="circulo"></div>
                    </div>
                    <div className="laptop">
                        <LazyLoadImage src={mockupLaptop} height={584} alt="Mock-up LondonManager Móvil" effect="opacity" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Present
