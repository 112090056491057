import React, { useContext } from 'react'
import './NavBar.css'
import Logo from '../../../images/logo-white.svg'
import { MenuContext } from '../../../contexts/MenuContext'
import { useLocation, useNavigate } from 'react-router-dom'

const NavBar = () => {
    const { menu, openClose, activeMenu, goToPage } = useContext(MenuContext);
    const location = useLocation();
    const navigate = useNavigate();

    const openPopup = (popup) => {
        if (location.pathname.length > 1) {
            navigate(`${location.pathname}/${popup}`)
        } else {
            navigate(`/inicio/${popup}`)
        }
    }

    return (
        <>
            <div className={menu ? "nav-container menu" : "nav-container"}>
                <nav className="navbar">
                    <div className="logo" style={{ backgroundImage: `url(${Logo})` }}></div>
                    <div className="navegate">
                        <ul className="menu">
                            <li className={activeMenu === 'inicio' ? "active" : ""} onClick={() => goToPage('inicio')}>Inicio</li>
                            <li className={activeMenu === 'soluciones' ? "active" : ""} onClick={() => goToPage('soluciones')}>Soluciones</li>
                            <li className={activeMenu === 'clientes' ? "active" : ""} onClick={() => goToPage('clientes')}>Clientes</li>
                            <li className={activeMenu === 'preguntas-frecuentes' ? "active" : ""} onClick={() => goToPage('preguntas-frecuentes')}>Preguntas Frecuentes</li>
                        </ul>
                        <div className="boton-menu" onClick={() => openClose()}>
                            <div className={menu ? "line1 close" : "line1"}></div>
                            <div className={menu ? "line2 close" : "line2"}></div>
                            <div className={menu ? "line3 close" : "line3"}></div>
                        </div>
                        <div className="buttons">
                            <button className="button primary mr-8" onClick={() => openPopup('solicitar')}>¡Quiero dar el salto!</button>
                            <a href="https://londonmanager.com/2021" className="button secondary">Ingresar</a>
                        </div>
                    </div>
                </nav>
            </div>
            <div className={menu ? "menu-mobile-container show" : "menu-mobile-container"}>
                <div className="menu-mobile-contain">
                    <div className="buttons-container">
                        <div style={{ width: "100%", marginTop: "-2rem"}}>
                            <h5 className={activeMenu === 'inicio' ? "active" : ""} onClick={() => goToPage('inicio')}>Inicio</h5>
                            <h5 className={activeMenu === 'soluciones' ? "active" : ""} onClick={() => goToPage('soluciones')}>Soluciones</h5>
                            <h5 className={activeMenu === 'clientes' ? "active" : ""} onClick={() => goToPage('clientes')}>Clientes</h5>
                            <h5 className={activeMenu === 'preguntas-frecuentes' ? "active" : ""} onClick={() => goToPage('preguntas-frecuentes')}>Preguntas Frecuentes</h5>
                        </div>
                    </div>
                    <div className="button complete primary" onClick={() => openPopup('solicitar')}>Solicitar prueba gratuita</div>
                    <a href="https://londonmanager.com/2021" className="button complete secondary">Ingresar</a>
                </div>
            </div>
        </>
    )
}
export default NavBar