import React, { useEffect } from 'react'
import Accordion from './Accordion'
import Aos from 'aos'
import './Faqs.css'

const Faqs = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    return (
        <div id="preguntas-frecuentes" className="faqs-container">
            <h3 data-aos="fade" style={{ marginTop: 0 }}>Nos anticipamos a algunas preguntas<br />que te estarás haciendo:</h3>
            <div data-aos="fade" className="questions-container">
                <Accordion />
            </div>
        </div>
    )
}

export default Faqs
