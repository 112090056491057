const faqs = [
  {
    id: 1,
    question: '¿Qué precio tiene el sistema?',
    answer:
      'Contamos con diferentes planes acordes al tipo de negocio y cantidad de puntos de venta que tenga. El mismo tiene un costo de alta (entre 40-80 usd según el tipo de sistema) y luego un mantenimiento mensual (entre 8-12 usd). Es por esto que, brindamos una prueba gratuita de 7 días, una vez que la solicites te la activamos en menos de 24 hs., y te enviamos la cotización a tu medida.'
  },
  {
    id: 2,
    question: '¿Es necesario contar con una impresora fiscal?',
    answer:
      'No, no es necesario. El London Manager utiliza un servicio denominado Web Service, el cual permite enviar automáticamente los datos de facturación a la AFIP. Es una de las grandes ventaja de nuestro sistema, ya que no tendrás que ponerte en gastos comprando una controladora fiscal.'
  },
  {
    id: 3,
    question: '¿Puedo hacer facturas online?',
    answer:
      'Sí, claro. Vas a poder emitir todo tipo de comprobantes. Factura A, B, C, Recibo X, etc. dependiendo de tus necesidades. '
  },
  {
    id: 4,
    question: '¿Puedo operar con varias cajas a la vez?',
    answer:
      'Sí, vas a poder tener configuradas cuantas cajas necesites para gestionar el negocio.'
  },
  {
    id: 5,
    question: '¿Puedo gestionar mis gastos?',
    answer:
      'Sí, vas a poder llevar un excelente control de todos tus gastos, tanto fijos como variables. '
  },
  {
    id: 6,
    question: '¿Puedo usarlo con cualquier computadora?',
    answer:
      'Sí, sólo vas a necesitar una computadora con conexión a internet y un navegador (recomendamos usar Google Chrome). El sistema es compatible tanto con computadoras de escritorio como también laptops, con cualquier sistema operativo (Windows ó Mac).'
  },
  {
    id: 7,
    question: '¿Qué pasa si me quedo sin conexión a internet?',
    answer:
      'Sí. Si bien recomendamos tener siempre una computadora en el negocio, vas a poder ingresar al sistema y hacer uso de todas las funcionalidades con cualquier dispositivo que tenga conexión a internet y un navegador.'
  },
  {
    id: 8,
    question: '¿Qué pasa si me quedo sin internet?',
    answer:
      'En caso de quedarte sin internet, el sistema está optimizado para funcionar perfectamente compartiendo  los datos del celular (4G) y a su vez, contamos con una plataforma offline para negocios que tienen mucho flujo de ventas y no disponen de buena conexión. En tal caso, sólo tendrás que solicitarnos la instalación y lo haremos en el transcurso de 72 horas hábiles.'
  },
  {
    id: 9,
    question: '¿Puedo tener varias impresoras instaladas? ',
    answer:
      'Sí. Se pueden tener varias impresoras instaladas a la vez. Por ejemplo, si tenés un restaurante, podrás tener una impresora por caja, y la impresora de comandas en cocina.'
  },
  {
    id: 10,
    question: '¿Hay un limite de usuarios?',
    answer:
      'No, vas a poder tener cuantos usuarios necesites para gestionar tu negocio. Por ejemplo, tener un usuario como dueño, y configurar otros perfiles para empleados, contadores, entre otros, con diferentes niveles de permisos.'
  }
]

export default faqs
