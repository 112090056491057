import React, { useEffect } from 'react'
import imgSkillsMobile from "../../../images/moreskills-2.png"
import { useLocation, useNavigate } from 'react-router-dom'
import Aos from 'aos'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import "./MoreSkills.css"
import 'react-lazy-load-image-component/src/effects/opacity.css';

const list = [
    "No vas a perderte de nada, sólo necesitás conexión a internet.",
    "Vas a poder ver lo que se está vendiendo en todo momento.",
    "Con el chat interno, dejale mensajes a tus socios y empleados.",
    "Enterate al instante cuáles fueron las ventas del día. ",
    "Revisá tus cuentas, gastos, facturas, proveedores, lo que quieras."
]

const MoreSkills = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    const location = useLocation();
    const navigate = useNavigate();

    const openPopup = (popup) => {
        navigate(`${location.pathname}/${popup}`)
    }

    return (
        <div className="more-skills-container">
            <div data-aos="fade" className="image-skills"></div>
            <div className="contain-skills">
                <div className="contain-intern">
                    <h2 data-aos="fade" style={{ color: "var(--white)" }}>Controlá tu negocio donde <br />quiera que estés.</h2>
                    <br />
                    <ul data-aos="fade">
                        {
                            list.map((text, index) => (
                                <li key={index}>{text}</li>
                            ))
                        }
                    </ul>
                    <br /><br /><br />
                    <button data-aos="fade" className="button primary" onClick={() => openPopup('solicitar')}>Solicitar prueba gratuita</button>
                </div>
            </div>
            <div data-aos="fade" className="image-skill-mobile">
                <LazyLoadImage src={imgSkillsMobile} width="100%" alt="Una mano sosteniendo un celular donde se visualiza London Manager." effect="opacity" />
            </div>
        </div>
    )
}

export default MoreSkills
