import React, { useState } from 'react'
import InputCss from '../Input.module.css'

const InputCelular = ({ label, name, placeholder }) => {
    const [value, setValue] = useState('')
    const [valid, setValid] = useState(false)

    const handleChange = (evt) => {
        let text = evt.target.value;
        text = text.replace(/ /g, "");
        text = text.replace(/[^0-9]/g, "");
        if (text.length > 9) {
            if(!valid) setValid(true)
        } else {
            if(valid) setValid(false)
        }

        if (text.length < 13) {
            const celular = text.split("");
            for (let i = 0; i < 10; i++) {
                if (!celular[i]) { celular[i] = ''; }
            }
            let num1 = `${celular[0]}${celular[1]}${celular[2]}`;
            let num2 = `${celular[3]}${celular[4]}${celular[5]}`;
            let num3 = `${celular[6]}${celular[7]}${celular[8]}${celular[9]}`;
            let codigoDos = parseInt(`${celular[0]}${celular[1]}`);
            let codigoCuatro = parseInt(`${celular[0]}${celular[1]}${celular[2]}${celular[3]}`);
            if (codigoDos === 11) {
                num1 = codigoDos;
                num2 = `${celular[2]}${celular[3]}${celular[4]}${celular[5]}`;
            }
            if (codigoCuatro === 2966 || codigoCuatro === 2954 || codigoCuatro === 2920 || codigoCuatro === 2901) {
                num1 = codigoCuatro;
                num2 = `${celular[4]}${celular[5]}${celular[6]}`;
                num3 = `${celular[7]}${celular[8]}${celular[9]}`;
            }
            if (num3 && num2 && num1) {
                setValue(`${num1} ${num2} ${num3}`);
            } else if (num2 && num1) {
                setValue(`${num1} ${num2}`);
            } else if (num1) {
                setValue(`${num1}`);
            } else {
                setValue(text);
            }
        }
    }

    return (
        <div className={InputCss.input_contain}>
            <input autoComplete="off" className={valid ? `${InputCss.input_valido}` : ""} name={name} value={value} onChange={handleChange} placeholder={placeholder} />
            <label className={InputCss.label}>{label}</label>
        </div>
    )
}

export default InputCelular
