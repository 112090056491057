import React, { useEffect } from 'react'
import CarouselTypes from '../../common/CarouselTypes/CarouselTypes'
import ItemSkill from '../../common/ItemSkill/ItemSkill'
import './Features.css'
import icon1 from '../../../images/icons-skills/icon1.svg'
import icon2 from '../../../images/icons-skills/icon2.svg'
import icon3 from '../../../images/icons-skills/icon3.svg'
import icon4 from '../../../images/icons-skills/icon4.svg'
import icon5 from '../../../images/icons-skills/icon5.svg'
import icon6 from '../../../images/icons-skills/icon6.svg'
import icon7 from '../../../images/icons-skills/icon7.svg'
import icon8 from '../../../images/icons-skills/icon8.svg'
import Aos from 'aos';

const items = [
    {
        icon: icon1,
        title: "Controlar tu Stock",
        description: "Controlá el stock de tus productos e insumos, en cuantos depósitos quieras. Además, podés tener listas de precios diferenciadas. "
    },
    {
        icon: icon2,
        title: "Ahorrar tiempo y esfuerzo",
        description: "Administrá tu negocio al 100% desde un sólo lugar. Con ésto, vas a ahorrar mucho tiempo y esfuerzo. Adiós Excel y papeles. "
    },
    {
        icon: icon3,
        title: "Proyectar tus Ventas",
        description: "Vas a poder saber facilmente qué días y horarios se vende más, qué productos y de qué forma. Así podrás proyectarte en el tiempo."
    },
    {
        icon: icon4,
        title: "Encontrar Oportunidades",
        description: "Con reportes estadísticos vas a poder saber qué es lo que más funciona, evitando riesgos y encontrando oportunidades."
    },
    {
        icon: icon5,
        title: "Gestionar tus Gastos",
        description: "Llevá el control de tus gastos fijos y variables como nunca antes. Vos elegís cuándo y cómo pagarlos, sin que se te pase nada. "
    },
    {
        icon: icon6,
        title: "Facturar facilmente",
        description: "Facturá tus ventas de una manera fácil y rápida. Vas a poder emitir todo tipo de comprobante avalados por la AFIP."
    },
    {
        icon: icon7,
        title: "Administrar tus Cuentas",
        description: "Si tenés más de una cuenta bancaria y manejás efectivo, vas a poder tener el control de tu dinero como nunca antes."
    },
    {
        icon: icon8,
        title: "Controlar tus Puntos de Venta",
        description: "Como si estuvieras en Netflix, con tan sólo click vas a poder entrar y operar desde el punto de venta que quieras. "
    }
]

const Features = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    return (
        <>
            <div id="soluciones" className="features-container">
                <div className="features-contain text-center">
                    <h2 data-aos="fade" style={{marginBottom: 15}}>Adaptado a todos los rubros.</h2>
                    <p data-aos="fade" className="small min">Un sistema preparado para todo tipo de negocio. Además, podés tener<br />varias empresas o puntos de venta en el mismo lugar.</p>
                    <div data-aos="fade">
                        <CarouselTypes />
                    </div>
                    <br /><br /><br />
                    <h3 data-aos="fade" style={{marginBottom: 15}}>Vas a tener la casa en orden,<br />como siempre quisiste.</h3>
                    <p data-aos="fade" className="small min">Estas son sólo algunas soluciones.</p>
                    <br />
                    <div className="items-skill-container-top">
                        <div data-aos="fade" className="items-skills-container">
                            {
                                items.map((item, index) => (
                                    <ItemSkill item={item} key={`item-skill-${index}`} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Features