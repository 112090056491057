import './App.css';
import './aos.css';
import { MenuProvider } from './contexts/MenuContext';
import { SolicitudProvider } from './contexts/SolicitudContext';
import RouteNames from './router/RouteNames';

function App() {
  return (
    <div className="App">
      <SolicitudProvider>
        <MenuProvider>
          <RouteNames />
        </MenuProvider>
      </SolicitudProvider>
    </div>
  );
}

export default App;
