import React, { createContext, useState } from "react";

export const SolicitudContext = createContext();

export const SolicitudProvider = ({ children }) => {
    const [tipoSolicitud, setTipoSolicitud] = useState('comercial')

    const changeTipoSolicitud = (solicitud) => {
        setTipoSolicitud(solicitud)
    }

    return (
        <SolicitudContext.Provider value={{ tipoSolicitud, changeTipoSolicitud }}>
            {children}
        </SolicitudContext.Provider>
    )
}