import React from 'react'
import Flickity from "react-flickity-component"
import './CarouselReviews.css'
import ItemReview from '../ItemReview/ItemReview'
import imgMarcelo from '../../../images/reviews/marcelo.jpg'
import imgEliseo from '../../../images/reviews/eliseo.jpg'
import imgMaximiliano from '../../../images/reviews/maximiliano.jpg'
import imgAgustin from '../../../images/reviews/agustin.jpg'

const flickityOptions = {
    initialIndex: 0,
    prevNextButtons: true,
    pageDots: true,
    contain: true,
    fullscreen: true
    // draggable: false
}

const CarouselReviews = () => {
    return (
        <Flickity options={flickityOptions}>
            <ItemReview item={
                {
                    review: '“Con el London Manager puedo saltar de un negocio a otro sin moverme de lugar, y saber cómo están las cosas en ese momento. Algo que no lo podía hacer antes de conocerlo, ya que el tiempo no me lo permitía.”',
                    imageAuthor: imgMarcelo,
                    author: "Marcelo Ocampo",
                    field: "CEO . Pepe Guapo"
                }
            } />
            <ItemReview item={
                {
                    review: '“Desde que usamos London Manager, puedo seguir estando al tanto de lo que sucede en mi negocio, aún cuando no puedo estar. Por diferentes razones a veces no puedo ir, y con este sistema puedo puedo ver todo desde el celular.“',
                    imageAuthor: imgEliseo,
                    author: "Eliseo Valiente",
                    field: "Socio Fundador . Monte Adentro"
                }
            } />
            <ItemReview item={
                {
                    review: '“Pasar la gestión de mi negocio al London Manager fue la mejor decisión. Antes usábamos un sistema para vender, y otras planillas de Excel para diferentes cuestiones. Hoy podemos tener todo en un sólo lugar, ahorrando tiempo y esfuerzo.”',
                    imageAuthor: imgMaximiliano,
                    author: "Maximiliano Álvarez",
                    field: "CEO . Paseo Wynwood"
                }
            } />
            <ItemReview item={
                {
                    review: '“Con el London Manager puedo fabricar los propios productos que vendo en la dietética, algo que antes me resultaba imposible. Tener un control exacto del stock, de los gastos y de las ventas es lo mejor que me pudo pasar.”',
                    imageAuthor: imgAgustin,
                    author: "Agustín Aquino",
                    field: "Dueño . Sano de Raíz"
                }
            } />
        </Flickity>
    )
}

export default CarouselReviews
