import React, { useContext, useEffect } from 'react'
import Present from '../components/layout/Present/Present'
import NavBar from '../components/common/NavBar/NavBar'
import Features from '../components/layout/Features/Features';
import MoreSkills from '../components/layout/MoreSkills/MoreSkills';
import Partners from '../components/layout/Partners/Partners';
import Reviews from '../components/layout/Reviews/Reviews';
import Faqs from '../components/layout/Faqs/Faqs';
import Footer from '../components/layout/Footer/Footer';
import { useNavigate, useParams } from "react-router-dom";
import { MenuContext } from '../contexts/MenuContext';
import Solicitar from '../components/popup/Solicitar/Solicitar';

const Home = () => {
    const { activeMenu, changeMenu } = useContext(MenuContext);
    const { toPage, toPopup } = useParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        // const elPopup = toPopup ? `/${toPopup}` : "";
        setTimeout(() => {
            let initial;
            if (toPage) {
                initial = toPage;
                changeMenu(initial)
                let toTop = document.getElementById(initial).offsetTop-90;
                window.scrollTo(0, toTop);
            }
        }, 200)
        // eslint-disable-next-line 
    }, [])

    useEffect(() => {
        const funcScroll = () => {
            const pageSoluciones = document.getElementById('soluciones').offsetTop - 91;
            const pageClientes = document.getElementById('clientes').offsetTop - 91;
            const pageFaqs = document.getElementById('preguntas-frecuentes').offsetTop - 91;
            const altY = window.scrollY;
            const elPopup = toPopup ? `/${toPopup}` : "";

            if (altY > 90) {
                let elemento = document.querySelectorAll('.navbar .navegate .menu li');
                for(let i = 0; i < elemento.length; i++)
                    elemento[i].style.color = "white";
            } else {
                let elemento = document.querySelectorAll('.navbar .navegate .menu li');
                for(let i = 0; i < elemento.length; i++)
                    elemento[i].style.color = "var(--gray-dark)";
            }
            if (altY >= 0 && altY < pageSoluciones) {
                if (activeMenu !== 'inicio') {
                    document.title = "London Manager Argentina - Sistema de Gestión";
                    changeMenu('inicio');
                    navigate('/inicio'+elPopup);
                }
            }
            if (altY >= pageSoluciones && altY < pageClientes) {
                if (activeMenu !== 'soluciones') {
                    document.title = "Soluciones superadoras - London Manager";
                    changeMenu('soluciones');
                    navigate('/soluciones'+elPopup);
                }
            }
            if (altY >= pageClientes && altY < pageFaqs) {
                if (activeMenu !== 'clientes') {
                    document.title = "Conocé a nuestros clientes - London Manager";
                    changeMenu('clientes');
                    navigate('/clientes'+elPopup);
                }
            }
            if (altY >= pageFaqs) {
                if (activeMenu !== 'preguntas-frecuentes') {
                    document.title = "Atendemos tus dudas - London Manager";
                    changeMenu('preguntas-frecuentes');
                    navigate('/preguntas-frecuentes'+elPopup);
                }
            }
        }
        window.addEventListener("scroll", funcScroll);
        return () => {
            window.removeEventListener("scroll", funcScroll)
        }
    }, [activeMenu, changeMenu, navigate, toPopup]);

    return (
        <>
            {toPopup === "solicitar" &&
                <Solicitar />
            }
            <NavBar />
            <Present />
            <Features />
            <MoreSkills />
            <Partners />
            <Reviews />
            <Faqs />
            <Footer />
        </>
    )
}

export default Home
