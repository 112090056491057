import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

const Footer = () => {
    return (
        <div className="footer-container">
            <p className="medium">London Manager. Un producto del <Link to="http://londonmanager.com">London Estudio</Link>.</p>
            <p className="medium"><a href="https://instagram.com" target="_blank" rel="noreferrer">Instagram</a><span className="circle"></span><a href="https://wa.link/faljwt" target="_blank" rel="noreferrer">Whatsapp</a></p>
        </div>
    )
}

export default Footer
