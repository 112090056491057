import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { SolicitudContext } from '../../../contexts/SolicitudContext';
import './ItemType.css'

const Item = ({ item }) => {
    const [view, setView] = useState(false)
    const { id, image, altImage, title, description, features } = item;
    const location = useLocation();
    const navigate = useNavigate();
    const { changeTipoSolicitud } = useContext(SolicitudContext);

    const openPopup = (popup, nuevaSolicitud) => {
        let solicitud = '';
        if (nuevaSolicitud === 'Tiendas') {
            solicitud = 'comercial';
        } else if (nuevaSolicitud === 'Gastronómicos') {
            solicitud = 'gastronomico';
        } else if (nuevaSolicitud === 'Fabricantes') {
            solicitud = 'fabricante';
        }
        changeTipoSolicitud(solicitud);
        navigate(`${location.pathname}/${popup}`)
    }

    const toDescription = () => {
        setView(!view)
    }

    return (
        <div className="card-rubro">
            <div className="card-rubro-contain">
                {
                    view ?
                        <div className="card-rubro-container-description" style={{ boxShadow: "0px 12px 40px rgba(143, 151, 216, 0.6)"}}>
                            <div className="contain-rubro">
                                <h5 style={{marginBottom: 20}}>Estas son algunas soluciones:</h5>
                                <ul>
                                    {features.map((point, index) => (
                                        <li key={`features-${id}-${index}`}>{point}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="contain-bottom">
                                <p className="small" style={{color: "var(--white)", cursor: "pointer"}} onClick={toDescription}>
                                    <b>VER MENOS</b>
                                </p>
                                <button className="button secondary" onClick={() => openPopup('solicitar', title)}>SOLICITAR PRUEBA</button>
                            </div>
                        </div>
                        :
                        <div className="card-rubro-container-normal">
                            <img src={image} alt={altImage} />
                            <div className="contain-rubro">
                                <h4>{title}</h4>
                                <p className="medium">{description}</p>
                                <p className="small" style={{color: "var(--primary)", cursor: "pointer", display: "inline-block"}} onClick={toDescription}>
                                    <b>VER MAS</b>
                                </p>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default Item
