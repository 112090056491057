import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { SolicitudContext } from '../../../contexts/SolicitudContext'
import InputCelular from '../../inputs/InputCelular/InputCelular'
import InputEmail from '../../inputs/InputEmail/InputEmail'
import InputText from '../../inputs/InputText/InputText'
import Popup from '../Popup.module.css'
import CSS from './Solicitar.module.css'
import emailjs from 'emailjs-com'

const Solicitar = () => {
    const [estado, setEstado] = useState('solicitar');
    const [error, setError] = useState(false)
    const { tipoSolicitud, changeTipoSolicitud } = useContext(SolicitudContext);
    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        const html = document.getElementsByTagName('html');
        html[0].style.overflow = "hidden";
        return () => {
            html[0].style.overflow = "auto";
        }
    }, [])
    
    const closePopup = () => {
        const actualPath = location.pathname;
        const paths = actualPath.split("/");
        paths.pop();
        const routeBack = paths.join("/");
        navigate(routeBack)
    }

    // FUNCION PARA COMPROBAR EMAIL
    // eslint-disable-next-line
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    
    // ENVIO DEL FORM
    const sendEmail = (e) => {
        e.preventDefault();
        setEstado('enviando');
        let searchError = false;
        const formNombre = e.target.nombre.value;
        const formEmail = e.target.email.value;
        const formCelular = e.target.celular.value;
        if (formCelular.length !== 12) {
            searchError = 'Por favor ingresá tu celular correctamente.'
        }
        if (!validEmailRegex.test(formEmail)) {
            searchError = 'Por favor ingresá un correo electrónico correcto.'
        }
        if (formNombre.length < 6) {
            searchError = 'Por favor ingresá tu nombre y apellido.'
        }

        // VERIFICACIÓN DE EMAIL
        if (searchError) {
            setError(searchError);
            setEstado('solicitar');
        } else {
            const formData = {
                nombre: formNombre,
                email: formEmail,
                celular: formCelular,
                tipo: tipoSolicitud
            }
            emailjs.send('service_6fnbn5g', 'template_ue330kl', formData, 'user_ZqVnGBPaNZpC3iUG2lPYA')
                .then((result) => {
                    setEstado('enviado');
                }, (err) => {
                    setError('Hubo un problema en el envío. Por favor intentá nuevamente.')
                    setEstado('solicitar');
                });
        }

      };
    
    return (
        <div className={Popup.popup_container}>
            <div className={Popup.popup_contain}>
                {estado === 'enviado' &&
                    <>
                    <div className={Popup.body_contain} style={{ textAlign: "center", borderRadius: "24px", padding: "7rem 2rem" }}>
                        <button className={Popup.close_icon_gris} onClick={() => closePopup()}></button>
                        <div className={CSS.sendok_contain}>
                            <div className={CSS.check_state_img}></div>
                            <h4 style={{ color: "var(--primary)" }}>¡Estamos listos!</h4>
                            <h6 style={{ display: "inline-block", width: "100%", maxWidth: 521 }}>Te enviamos un correo electrónico con las instrucciones para que puedas empezar a usar el sistema y te haremos unas consultas para confeccionar un presupuesto a tu medida.</h6>
                            <h6 className={CSS.text_important_icon} style={{ display: "inline-block", width: "auto", color: "var(--gray-dark)" }}><font className={CSS.important_icon}></font> Si no ves el correo, chequeá la bandeja de Spam y Promociones.</h6>
                            <div className="button primary" style={{ marginTop: "1rem" }} onClick={() => closePopup()}>Entendido</div>
                        </div>
                    </div>
                    </>
                }
                {estado === 'enviando' &&
                    <>
                    <div className={Popup.body_contain} style={{ textAlign: "center", borderRadius: "24px", padding: "7rem 2rem" }}>
                        <button className={Popup.close_icon_gris} onClick={() => closePopup()}></button>
                        <div className={CSS.sendok_contain}>
                            <div className={CSS.loader_state_img}></div>
                            <h6 style={{ color: "var(--gray-dark)" }}>Enviando...</h6>
                        </div>
                    </div>
                    </>
                }
                {estado === 'solicitar' &&
                    <>
                    <button className={Popup.close_icon} onClick={() => closePopup()}></button>
                    <div className={Popup.header_contain}>
                        <h4>¡Felicitaciones! Ya diste el primer paso. <font style={{fontSize: 30}}>👏</font></h4>
                        <p className="small">Necesitamos que nos cuentes qué tipo de negocio tenés y que nos dejes tus datos básicos para que podamos activar tu cuenta de prueba por 7 días, y a su vez, mandarte un presupuesto a tu medida. </p>
                    </div>
                    <div className={Popup.body_contain}>
                        <h6 style={{marginTop: 0}}><b>Seleccioná el tipo de negocio que tenés:</b></h6>
                        <div className={CSS.options_container}>
                            <div className={tipoSolicitud === 'comercial' ? `${CSS.option_active}` : `${CSS.option}`} onClick={() => changeTipoSolicitud('comercial')}>
                                <h5>Comercial</h5>
                                <p className="small">Locales de ropa, tiendas de bebida, quioscos, etc.</p>
                            </div>
                            <div className={tipoSolicitud === 'gastronomico' ? `${CSS.option_active}` : `${CSS.option}`} onClick={() => changeTipoSolicitud('gastronomico')}>
                                <h5>Gastronómico</h5>
                                <p className="small">Restaurantes, bares, rotiserías, heladerías, cafeterías, etc.</p>
                            </div>
                            <div className={tipoSolicitud === 'fabricante' ? `${CSS.option_active}` : `${CSS.option}`} onClick={() => changeTipoSolicitud('fabricante')}>
                                <h5>Fabricante</h5>
                                <p className="small">Fábricas de helado, panificados, dietéticas, fragmentadoras, etc.</p>
                            </div>
                        </div>
                        <br />
                        <h6><b>Dejanos tus datos para activar tu cuenta:</b></h6>
                        {error &&
                            <h6 style={{textAlign: "center", color: "var(--gray-dark)"}}><font className={CSS.important_icon}></font> {error}</h6>
                        }
                        <form onSubmit={sendEmail}>
                            <div className={CSS.form_container}>
                                <InputText name="nombre" label="Nombre y Apellido" />
                                <InputEmail name="email" label="Correo electrónico" />
                                <InputCelular name="celular" label="Celular" placeholder="Ej. 362 440 8476" />
                                <button className="button primary" style={{ width: 145, marginBottom: 8 }}>Enviar solicitud</button>
                            </div>
                        </form>
                    </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Solicitar
