import React from 'react'
import Flickity from "react-flickity-component"
import './CarouselPartners.css'

const CarouselPartners = ({ partners, flickityOptions, type }) => {
    return (
        <>
            {
                type === 'desktop' ?
                    (
                        <Flickity options={flickityOptions}>
                            {
                                partners.map((part, index) => (
                                    part === 'vacio' ?
                                        (
                                            <div className="item-vacio" key={index}></div>
                                        )
                                        :
                                        (
                                            <div className="item-partner" key={index}>
                                                <img src={part} alt="Partners" />
                                            </div>
                                        )
                                ))
                            }
                        </Flickity>
                    )
                    :
                    (
                        <Flickity options={flickityOptions}>
                            {
                                partners.map((part, index) => (
                                    <div className="item" key={index}>
                                        <div className="item-partner">
                                            <img src={part[0]} alt="Partners" />
                                        </div>
                                        <div className="item-partner">
                                            <img src={part[1]} alt="Partners" />
                                        </div>
                                        <div className="item-partner">
                                            <img src={part[2]} alt="Partners" />
                                        </div>
                                        <div className="item-partner">
                                            <img src={part[3]} alt="Partners" />
                                        </div>
                                        <div className="item-partner">
                                            <img src={part[4]} alt="Partners" />
                                        </div>
                                        <div className="item-partner">
                                            <img src={part[5]} alt="Partners" />
                                        </div>
                                    </div>
                                ))
                            }
                        </Flickity>
                    )
        }
        </>
    )
}

export default CarouselPartners
