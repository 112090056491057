import React from 'react'
import Flickity from "react-flickity-component"
import './CarouselTypes.css'
import tiendas from '../../../images/tiendas.jpg'
import gastronomicos from '../../../images/gastronomicos.jpg'
import fabricantes from '../../../images/fabricantes.jpg'
import ItemType from '../ItemType/ItemType'

const flickityOptions = {
    initialIndex: 0,
    prevNextButtons: false,
    pageDots: true,
    contain: true
    // draggable: false
}

const CarouselTypes = () => {
    return (
        <Flickity options={flickityOptions}>
            <ItemType item={
                {
                    id: 1,
                    image: tiendas,
                    altImage: "Imagen de Tiendas",
                    title: "Tiendas",
                    description: "El sistema se adapta a todo tipo de negocios. Tales como tiendas de ropa, multirubros, kioscos, entre otros.",
                    features: [
                        'Controlar el Stock general y/o por depósitos.',
                        'Vender y comprar mercadería',
                        'Ver el historial y movimientos de todo tipo.',
                        'Tener Estadísticas de productos, clientes, marcas, medios de pago, etc.',
                        'Gestionar todas tus cuentas.',
                        'Agendar clientes y proveedores.',
                        'Hacer arqueos de cajas y tener balances. ',
                        'Ordenar tus cuentas bancarias y medios de pago.'
                    ]
                }
            } />
            <ItemType item={
                {
                    id: 2,
                    image: gastronomicos,
                    altImage: "Imagen de Gastronómicos",
                    title: "Gastronómicos",
                    description: "Restaurantes, Bares, Pubs. Es una plataforma con la que vas a poder hacer ventas de Salón o Mostrador, como también por Delivery.",
                    features: [
                        'Controlar el stock de ingredientes e insumos.',
                        'Hacer ventas de salón, por mostrador y delivery.',
                        'Elaborar recetas y fabricar todos tus platos.',
                        'Conocer el costo real de tus platos.',
                        'Seguimiento de pedidos.',
                        'Impresión de comandas en cocina. ',
                        'Pantalla de pedidos Listos y en Preparación.',
                        'Creación de usuarios para todo el personal.'
                    ]
                }
            } />
            <ItemType item={
                {
                    id: 3,
                    image: fabricantes,
                    altImage: "Imagen de Fabricantes",
                    title: "Fabricantes",
                    description: "Ideal para empresas que fabrican o fraccionan mercadería para vender a terceros o a sus propios puntos de venta.",
                    features: [
                        'Fabricar y/o fragmentar productos.',
                        'Control de stock de productos e insumos.',
                        'Reportes de fabricación y ventas.',
                        'Control de gastos fijos y variables.',
                        'Gestión de recursos humanos.',
                        'Generación de presupuestos y ordenes de compra.',
                        'Facturación.'
                    ]
                }
            } />
        </Flickity>
    )
}

export default CarouselTypes
