import React, { createContext, useState } from "react";

export const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
    const [menu, setMenu] = useState(false)
    const [activeMenu, setActiveMenu] = useState()

    const openClose = () => {
        setMenu(!menu)
    }
    
    const changeMenu = (section) => {
        setActiveMenu(section)
    }

    const goToPage = (section) => {
        let toTop = document.getElementById(section).offsetTop;
        if (section === 'clientes' || section === 'preguntas-frecuentes') {
            toTop = toTop-90;
        }
        window.scrollTo({ top: toTop, behavior: 'smooth' });
        changeMenu(section)
        openClose(false)
    }

    return (
        <MenuContext.Provider value={{ menu, openClose, activeMenu, changeMenu, goToPage }}>
            {children}
        </MenuContext.Provider>
    )
}