import React from 'react'
import './ItemReview.css'

const ItemReview = ({ item }) => {
    const { review, imageAuthor, author, field } = item;
    return (
        <div className="item-container">
            <div className="item-review-container">
                <div className="review-contain">
                    <p className="large" style={{ color: "var(--gray-dark)" }}>{review}</p>
                </div>
                <div className="author-contain">
                    <div className="author-image">
                        <img src={imageAuthor} alt={`Foto de ${author}`} style={{marginRight: 15}} />
                    </div>
                    <div className="author-details">
                        <p className="medium" style={{margin: 0}}><b>{author}</b></p>
                        <p className="small" style={{margin: 0}}>{field}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemReview
