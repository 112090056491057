import React, { useEffect } from 'react'
import CarouselPartners from '../../common/CarouselPartners/CarouselPartners'
import "./Partners.css"
import monteAdentro from '../../../images/partners/monte-adentro.svg'
import saboratto from '../../../images/partners/saboratto.svg'
import sanoDeRaiz from '../../../images/partners/sano-de-raiz.svg'
import pepeGuapo from '../../../images/partners/pepe-guapo.svg'
import nuevoOrigen from '../../../images/partners/nuevo-origen.svg'
import paseoWynwood from '../../../images/partners/paseo-wynwood.svg'
import laRepublica from '../../../images/partners/la-republica.svg'
import digExpress from '../../../images/partners/dig-express.svg'
import santaMaria from '../../../images/partners/santa-maria.svg'
import donkeyRocks from '../../../images/partners/donkey-rocks.svg'
import dvBebidas from '../../../images/partners/dv-bebidas.svg'
import teresitaDonkin from '../../../images/partners/teresita-donkin.svg'
import useViewport from '../../../hooks/useViewport'
import Aos from 'aos'

const marcasDesktop = [
    'vacio', monteAdentro, saboratto, sanoDeRaiz, pepeGuapo, nuevoOrigen, paseoWynwood, laRepublica, digExpress, santaMaria, donkeyRocks, dvBebidas, teresitaDonkin, 'vacio'
]
const marcasMobile = [
    [monteAdentro, saboratto, sanoDeRaiz, pepeGuapo, nuevoOrigen, paseoWynwood], [laRepublica, digExpress, santaMaria, donkeyRocks, dvBebidas, teresitaDonkin]
]

const Partners = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    const { width } = useViewport();
    const breakpoint = 732;

    return (
        <div id="clientes" className="partners-container">
            <div className="partners-contain">
                <h2 data-aos="fade" style={{marginBottom: 15, marginTop: 0}}>Ya confían en nosotros</h2>
                <p data-aos="fade" className="large" style={{marginBottom: 0}}>Estos son sólo algunos de los clientes que ya dieron el salto.</p>
                <p data-aos="fade" className="large out" style={{marginTop: 0}}>Vos también podés vivir más tranquilo, como ellos.</p>
                <br /><br /><br /><br /><br />
                {width > breakpoint ?
                    (
                        <div data-aos="fade">
                            <div className="transp-left"></div>
                            <div className="transp-right"></div>
                            <CarouselPartners partners={marcasDesktop} flickityOptions={{
                                initialIndex: 0,
                                prevNextButtons: false,
                                pageDots: true,
                                contain: true,
                                groupCells: true,
                                cellAlign: 'left',
                                freeScroll: true
                                // draggable: false
                            }} type="desktop" />
                        </div>
                    )
                    :
                    (
                        <div data-aos="fade">
                            <CarouselPartners partners={marcasMobile} flickityOptions={{
                                initialIndex: 0,
                                prevNextButtons: false,
                                pageDots: true,
                                contain: true,
                                cellAlign: 'left',
                                freeScroll: false
                                // draggable: false
                            }} type="mobile" />
                        </div>
                    )
                }
                <br /><br /><br />
            </div>
        </div>
    )
}

export default Partners
