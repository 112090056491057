import React, { useState } from 'react'
import InputCss from '../Input.module.css'

const InputText = ({ label, name }) => {
    const [value, setValue] = useState('')
    const [valid, setValid] = useState(false)

    const handleChange = (evt) => {
        const text = evt.target.value;
        if (text.length > 6) {
            if(!valid) setValid(true)
        } else {
            if(valid) setValid(false)
        }
        setValue(text)
    }

    return (
        <div className={InputCss.input_contain}>
            <input autoComplete="off" className={valid ? `${InputCss.input_valido}` : ""} name={name} value={value} onChange={handleChange} />
            <label className={InputCss.label}>{label}</label>
        </div>
    )
}

export default InputText
